<template>
  <Card
    title="Physiothérapie et pelvi-périnéologie chez l'enfant"
    resume="Dès l’âge de 3 ans, il est possible de travailler avec l’enfant et les parents en ce qui concerne
les troubles mictionnels, d&#39;énurésie, de constipation, d&#39;encoprésie etc… les objectifs
thérapeutiques se basent en premier lieux sur une belle relation de confiance ainsi que la
valorisation de l’enfant.">
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    Les acteurs que peuvent être la famille ou l'école sont impliqués dans
    cette « rééducation ». Des moyens de traitement tels que le calendrier mictionnel, les dessins,
    le jeu, les techniques de relâchement ainsi que de prise de conscience sont utilisés pour guider
    l'enfant dans sa démarche. Un travail analytique avec le biofeedback peut être entamé, sans

    oublier pour autant de prendre l’enfant dans sa globalité et faire en sorte qu’il y ait un transfert
    des acquis de la rééducation dans son quotidien (travail de la respiration, conscience
    corporelle, etc…)
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'PelviPerineologyChildren',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;

}
</style>
