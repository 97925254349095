<template>
  <div id="card">
    <Header id="card-header"
            :is-content-visible="isContentVisible"
            @show-content="showContent"
            :title="title"
            :resume="resume"
    />
    <Footer
      id="card-footer"
      :is-content-visible="isContentVisible"
      @show-content="showContent"
    >
      <template v-slot:background>
        <slot name="background"></slot>
      </template>
      <slot></slot>
    </Footer>
  </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'Card',
  components: {
    Footer,
    Header
  },
  props: {
    title: String,
    resume: String
  },
  data: function () {
    return {
      isContentVisible: false
    }
  },
  methods: {
    showContent: function (value) {
      console.log('card-is-visible-before', this.isContentVisible)
      this.isContentVisible = value
      console.log('card-is-visible-after', this.isContentVisible)
    }
  }
}
</script>

<style scoped>
#card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

@media (max-width: 641px) {
  #card {
    grid-template-columns: 1fr;
  }
}

</style>
