<template>
  <div id="card-footer">
    <transition enter-active-class="animate__animated animate__zoomIn animate__faster"
                leave-active-class="animate__animated animate__fadeOut animate__faster">
      <div id="image" v-if="!isContentVisible">
        <slot name="background"></slot>
        <!--      <img alt="SVPhysio logo" src="../../assets/logo.png" class="logo">-->
        <!--      <img alt="SVPhysio logo" :src="require(image)" class="logo">-->
      </div>
    </transition>
    <div v-if="isContentVisible">
      <slot></slot>
    </div>
    <img alt="Logo close icon" src="@/assets/close.svg" class="icon-close" @click="onClose">
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    isContentVisible: Boolean
  },
  methods: {
    onClose: function () {
      this.$emit('show-content', false)
    }
  }
}
</script>

<style scoped>
#image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color-light);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#card-footer {
  height: 550px;
  background: rgb(249, 238, 223);
  background: linear-gradient(90deg, rgba(249, 238, 223, 1) 0%, rgba(218, 188, 143, 1) 100%);
  overflow: auto;
  padding: 20px 15%;
  max-height: 600px;
  color: var(--primary-color);
  position: relative;
  box-sizing: border-box;
  font-size: 1rem;
  text-align: justify;
  text-justify: inter-word;
}

.icon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 30px;
  cursor: pointer;
}
</style>
