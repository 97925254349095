<template>
  <Card
    title="Électrolyse percutanée thérapeutique"
    resume="L'électrolyse percutanée thérapeutique est une technique de physiothérapie invasive qui consiste en l'application guidée par ultrasons d'un courant galvanique à travers une aiguille de ponction qui produit un effet analgésique dans les tissus mous musculo-squelettiques et un processus inflammatoire local"
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    L'électrolyse thérapeutique percutanée active la première phase d’un processus régénératif paralysé dans une
    tendinopathie. Par ce processus, les tissus dégénérés sont détruits et une phase de réponse inflammatoire est
    déclenchée, nécessaire à tout processus de régénération, qui permet la phagocytose et la réparation du tissu
    affecté, principalement
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'Electrolyse',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 80px;
}
</style>
