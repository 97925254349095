<template>
  <Card
    title="Physiothérapie Pré &amp; Post-partum"
    resume="Le suivi en physiothérapie pendant la grossesse et après la naissance de votre enfant est
primordial. Les objectifs sont de pouvoir aborder la préparation physique et mental de

chacune, permettre à la femme d’être consciente des changements corporels et de le vivre
avec plaisir pour minimiser différents symptômes comme la douleur par exemple.">
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    Diverses techniques en thérapie manuelle, en drainage lymphatique ainsi que des conseils
    pour les activités de la vie quotidienne sont pratiquées, afin de vous aider à vivre une
    grossesse la plus harmonieuse possible.
    Les besoins de la femme en post-partum sont précisés par un bilan global dans un premier
    temps, puis par un bilan plus spécialisé au niveau de la région abdomino-pelvienne. Un travail
    de rééducation du périnée analytique est réalisé, afin de retrouver des capacités et sensations
    optimales. Comme toute maman souhaite bouger et pouvoir reprendre ses activités, un travail
    actif est réalisé, notamment par de la gymnastique hypopressive. Des conseils essentiels quant
    à la gestion des efforts et au retour au sport sont donnés en fonction des besoins et des
    objectifs de chacune.
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'PhysiotherapyPrePostPartum',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/postPartum.jpg')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
