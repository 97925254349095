<template>
  <Card title="Thérapie Manuelle Orthopédique"
        resume="La Thérapie Manuelle/Manipulative Orthopédique (TMO) est un champ spécialisé de la
physiothérapie pour la prise en charge des troubles du système Neuro-Musculo-Squelettique."
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    <p>
      La Thérapie Manuelle/Manipulative Orthopédique (TMO) inclut le raisonnement clinique du patient adulte et
      pédiatrique, ciblant une approche basée sur des preuves scientifiques, tout en intégrant le modèle
      bio-psycho-social
      du patient. Il s’agit d’une approche qui inclut des techniques manuelles et des exercices thérapeutiques.
    </p>
    <p>
      Chez les enfants, cela concerne les nouveaux-nés, adressés pour un torticolis congénital musculaire/postural
      ou
      pour une plagiocéphalie, les malpositions des petits pieds, le plexus braquial, tensions, ...mais aussi les
      plus
      grands qui consultent pour des attitudes scoliottiques /scolioses ou pour une réeducation post-traumatique
    </p>
    <p>
      Pour les adultes, les objectifs de la TMO sont par exemple de pouvoir améliorer la fonction, diminuer les
      symptômes douloureux, avec comme finalité, une amélioration de la qualité de vie dans les activités
      quotidiennes
    </p>
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'TMO',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/tmo.jpg')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
