<template>
  <div id="card-header">
    <div class="title">
      {{ title }}
    </div>
    <div class="separator"></div>
    <div class="resume">
      {{ resume }}
    </div>
    <div class="text button" @click="showMore">
      <div v-if="isContentVisible"> Fermer</div>
      <div v-else> En savoir plus</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String,
    resume: String,
    isContentVisible: Boolean
  },
  methods: {
    showMore: function () {
      console.log('header-isVisible', this.isContentVisible)
      this.$emit('show-content', !this.isContentVisible)
    }
  }
}
</script>

<style scoped>
#card-header {
  background-color: var(--primary-color);
  color: white;
  padding: 20px 0 60px;
  display: flex;
  flex-direction: column;
  height: 550px;
  box-sizing: border-box;
  overflow: auto;
}

.title {
  margin: 18px 42px !important;
  font-size: 2rem;
}

.resume {
  flex: 1;
  margin: 18px 42px !important;
}

.text {
  margin: 0 42px;
}

.separator {
  width: 160px;
  height: 16px;
  background: rgb(249, 238, 223);
  background: linear-gradient(90deg, rgba(249, 238, 223, 1) 0%, rgba(218, 188, 143, 1) 100%);
}

.button {
  border: 2px solid var(--secondary-color-light);
  padding: 16px;
  cursor: pointer;
}

.button:hover {
  border: 2px solid var(--secondary-color);
  color: var(--primary-color);
  background: linear-gradient(90deg, rgba(249, 238, 223, 1) 0%, rgba(218, 188, 143, 1) 100%);
}
</style>
