<template>
  <Card
    title="Neuromodulation Percutanée (NPM)"
    resume="La Neuromodulation Percutanée (NPM) est une technique invasive, basée sur la stimulation de points neuro-réactifs du corps à l'aide d'aiguilles de ponction sèche ou d'acupuncture et d'équipements d'électrothérapie"
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }">
      </div>
    </template>
    La Neuromodulation Percutanée (NPM) est une technique invasive, basée sur la stimulation de points
    neuro-réactifs du corps à l'aide d'aiguilles de ponction sèche ou d'acupuncture et d'équipements
    d'électrothérapie qui permet d'ajuster différents paramètres tels que : la fréquence, la largeur d'impulsion et
    l'intensité, pour obtenir un signal de manière plus sélective et efficace.
    L'application de celle-ci doit être effectuée de manière guidée par ultrasons afin d'être précise et sûre
    pendant le processus d'insertion de l'aiguille et le traitement ultérieur.
    L'introduction de cette technique dans la pratique clinique permet d'accélérer les temps de récupération, utile
    une fois la blessure établie, ainsi que dans une approche préventive des dysfonctionnements.
    Les applications de la neuromodulation percutanée dans les domaines suivants sont à souligner :
    <ul>
      <li>
        le traitement des douleurs aiguës et chroniques d'origine musculo-squelettique telles que les douleurs
        lombaires, postopératoires, neuropathiques, cervicales chroniques d'origine myofasciale, ATM, entre autres.
      </li>
      <li>
        restaurer la fonction du système nerveux, au niveau périphérique et central, dans les aspects liés aux
        dysfonctionnements sensoriels ou moteurs.
      </li>
      <li>
        améliorer la fonction neuromusculaire, les schémas de recrutement musculaire ainsi que le contrôle moteur.
      </li>
    </ul>
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'Npm',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 80px;
}
</style>
