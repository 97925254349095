<template>
  <Card
    title="Bandage Fonctionnel (taping)"
    resume="Si vous regardez une compétition ou êtes sportifs, vous avez certainement remarqué des
personnes portant des bandes adhésives de couleur sur le corps."
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    Il s’agit de bandes utilisées pour traiter différents troubles physiques, avec comme objectif
    « d’imiter la structure de la peau » pour maintenir le mouvement corporel par une contention
    élastique. Ces bandes permettent la diminution de la douleur et un retour aux activités de la
    vie quotidienne très rapidement
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'Taping',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
